import styles from "./ConnectButton.module.scss";

function ConnectButton({
  label,
  image,
  onClick,
}: {
  label: string;
  image: string;
  onClick: () => void;
}) {
  return (
    <button key={label} onClick={onClick} className={styles.walletBtn}>
      <img src={image} width={32} height={32} alt="MetaMask" />
      <span className={styles.connectButtonText}>{label}</span>
    </button>
  );
}

export default ConnectButton;
