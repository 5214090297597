import * as React from "react";
import styles from "./CloseButton.module.scss";

interface ICloseButtonProps {
  onClose: () => void;
}

const CloseButton: React.FunctionComponent<ICloseButtonProps> = ({
  onClose,
}) => {
  return (
    <div onClick={() => onClose()} className={styles.closeBtn}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="sc-1cchcrx-0 icSTTd"
      >
        <line x1="18" y1="6" x2="6" y2="18"></line>
        <line x1="6" y1="6" x2="18" y2="18"></line>
      </svg>
    </div>
  );
};

export default CloseButton;
